/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "me-south-1",
    "aws_cognito_region": "me-south-1",
    "aws_user_pools_id": "me-south-1_xnvMXgz55",
    "aws_user_pools_web_client_id": "1eokgqp7akh4e3o8j6oo45gv4v",
    "oauth": {
        "domain": "liively-prod-ae.auth.me-south-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "https://ae.order.liive.ly/sso-auth-success,https://ae.order.liively.app/sso-auth-success",
        "redirectSignOut": "https://ae.order.liive.ly/logout,https://ae.order.liively.app/logout",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL",
        "PHONE_NUMBER"
    ]
};


export default awsmobile;
